globalThis["__sentryRewritesTunnelPath__"] = "/monitoring/";
globalThis["SENTRY_RELEASE"] = {"id":"v1.44.4"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "/wine";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"

Sentry.init({
  beforeSend(event: Sentry.ErrorEvent) {
    if (
      event?.exception?.values &&
      event.exception.values[0]?.stacktrace?.frames &&
      event.exception.values[0].stacktrace.frames[0].filename === `<anonymous>`
    ) {
      return null
    }

    return event
  },

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  dsn: "https://d9704b28e17128ec2d515a5440041b7a@o216259.ingest.us.sentry.io/4507940377985025",

  environment: process.env.NEXT_PUBLIC_APP_ENV,

  ignoreErrors: [
    // https://stackoverflow.com/questions/72720387/cant-find-variable-autofillcallbackhandler
    "ReferenceError: Can't find variable: _AutofillCallbackHandler",
  ],

  // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
  tracesSampleRate: 0.01,
})
